<template>
  <v-container fluid class="pa-0 ma-0">

    <v-row no-gutters>

      <v-col cols="12">
        <v-toolbar
          dense
          flat
        >
          <v-toolbar-title>
            <v-btn
              text
              small
              @click="goList"
            >
              <v-icon small>mdi-view-list</v-icon>
              목록
            </v-btn>
          </v-toolbar-title>
        </v-toolbar>
      </v-col>

      <v-col v-show="!isId" cols="12">
        <v-alert
          prominent
          type="warning"
          class="ma-4 pa-10"
        >존재하지 않는 데이터 입니다</v-alert>
      </v-col>

      <!-- 구분: 기본정보 -->
      <v-col v-show="isId" cols="12">
        <v-row
          no-gutters
          align="center"
          justify="start"
        >
          <v-card
            outlined
            class="rounded-lg mx-4 mt-2 mb-2 py-2"
            width="1000"
          >
            <v-chip
              v-if="article.isTerminatedPassed || !article.status"
              small
              label
              color="grey"
              dark
              class="ml-4 mr-1 mt-2 mb-0"
            >종료</v-chip>
            <v-chip
              v-else
              small
              label
              color="light-green darken-2"
              dark
              class="ml-4 mr-1 mt-2 mb-0"
            >진행중</v-chip>
            <v-chip
              small
              label
              :color="article.typeColor"
              class="mt-2 mb-0"
            >{{ article.mType }}</v-chip>
            <v-card-title class="my-0">
              <v-icon class="mr-2">mdi-chevron-right</v-icon>
              <!-- <v-icon small class="mr-2">mdi-gamepad-circle-right</v-icon> -->
              {{ article.subject }}
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-subtitle class="py-2">
              등록일: {{ strDateFormat2(article.createdAt) }}
              {{ article.type === 2 && article.terminatedAt ? ` / 종료일: ${strDateFormat2(article.terminatedAt)}` : '' }}
            </v-card-subtitle>
          </v-card>
        </v-row>
      </v-col>

      <!-- 구분: 내용 -->
      <v-col v-show="isId" cols="12">
        <v-row
          no-gutters
          align="center"
          justify="start"
        >
          <v-card
            outlined
            class="rounded-lg mx-4 mt-2 mb-2 py-2"
            width="1000"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" class="text-body-2 px-3 py-2">
                  <div
                    id="vue2editorViewer"
                    class="vue2editorViewer text-body-1"
                    v-html="article.content"
                  ></div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row
          no-gutters
          align="center"
          justify="start"
        >
          <p></p>
        </v-row>
      </v-col>

    </v-row>

    <!-- 첨부파일 다운로드시 오버레이 -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-container>
</template>

<script>
// @: filters
import strDateFormat2 from '@/filters/strDateFormat2'

// 구분: 현재 디렉토리명
const currDir = 'notice'

export default {
  components: {
  },

  data: () => ({
    // 구분: 게시물 데이터
    articleId: 0,
    article: {},
    // 구분: 존재하는 아이디 인지 여부
    isId: true,
    // 구분: 오버레이 로딩
    overlay: false
  }),

  watch: {
    '$route' (to, from) {
      // console.log(`watch.route`)
      // console.log(to.params)
      // !! 넘겨준 파라미터가 있는 경우. 등록시엔 -R을 제거해야 한다.
      const paramIds = to.params.id.split('-')
      this.articleId = parseInt(paramIds[0], 10)
      if (paramIds.length > 1 && paramIds[1] === 'E') {
        // !! 수정시엔 '-E' 가 붙어서 넘어온다
        this.articleId = parseInt(paramIds[0], 10)
        // 다시 해당 아이디로 리다이렉트 시킨다.
        this.$router.push(`/${currDir}/${this.articleId}`)
      } else {
        // !! 수정이 아닌 경우
        this.articleId = parseInt(paramIds[0], 10)
        // 넘어온 id로 해당 게시물 정보를 가져와야 한다
        this.getArticle(this.articleId)
      }
    }
  },

  computed: {
  },

  mounted () {
    // console.log(this.$store.state.token)

    // 중요: 넘어온 id를 매칭시켜야 한다!
    // 등록시엔 '-R' 이 붙어오므로 분리해서 적용한다. 안그러면 뷰페이지에서 목록으로 보낼때마다 초기화 해버린다.
    const paramIds = this.$route.params.id.split('-')
    this.articleId = parseInt(paramIds[0], 10)

    // 넘어온 id로 해당 게시물 정보를 가져와야 한다
    this.getArticle(this.articleId)
  },

  methods: {
    strDateFormat2,
    dummy () {
      console.log('dummy dummy ~ ')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message, color: 'error', timeout: 5000, shaped: true })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (const key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 중요: 리스트를 리프레시 한다.
    refreshList (param = '') {
      this.$router.push(`/${currDir}/${param}`)
    },
    goList () {
      this.$router.push(`/${currDir}`)
    },
    // 구분: 상세정보(article) 가져오기
    async getArticle (id) {
      try {
        if (!id) throw new Error('[ERR-0] 잘못된 인수형식 입니다.')

        const { data } = await this.$axios.get(`lawork/member/getNoticeById/${id}`)
        if (!data.success) throw new Error(`[ERR-1] ${data.message}`)
        if (data.article) {
          // #1 상세정보 = 내용을 매칭하기
          this.article = data.article

          // #2 공지사항 확인작업! 만만치 않다!
          // 일반공지만 확인 > 긴급공지는 팝업에서 확인 버튼으로 확인하므로 스킵
          // if (this.article.type === 1) { // !![2021.10.14] 긴급공지도 처리하도록 수정함
          // }
          // [2021.10.14] 확인 함수로 다시 작성
          await this.confirmNotice(this.articleId)
        } else {
          // throw new Error('존재하지 않는 데이터 입니다!')
          this.isId = false
          this.overlay = true
          setTimeout(this.goList, 1000)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 공지사항을 확인으로 처리하기
    async confirmNotice (id) {
      try {
        const { data } = await this.$axios.post(`lawork/member/confirmNotice`, { id })
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        // !! 확인 후 userDB.notice 에 입력되면 리스트 리프레시
        if (data.insertId) {
          this.refreshList(`${this.articleId}-E`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
.vue2editorViewer p {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  /* padding-top: 2px !important; */
  /* padding-bottom: 2px !important; */
}
iframe {
  width: 640px !important;
  height: 360px !important;
}
</style>
